* {
	box-sizing: border-box;
	position: relative;
}

html {
	font-family: 'avenir-next';
}

:root {
    --active-min-height: 130px;
    --inactive-min-height: 70px;
    --padding-unit: 20px;
    --logo-container-height: 180px;
    --main-gap: 100px;
    --header-row-height: 3em;
    --light-gray: #F1F3F7;
    --time-per-resort: 20s;
}

@font-face {
    font-family: 'avenir-next';
    src: url(../public/fonts/AvenirNextLTPro-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir-next-bold';
    src: url(../public/fonts/AvenirNextLTPro-Bold.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
}


.Resorts {
	display: flex;
	height: 100vh;
	width: 100vw;
	background-color: var(--light-gray);
	gap: var(--main-gap); /* 4em */
	padding-left: 0;
	padding-right: var(--main-gap);
}

.Resorts-list {
	display: flex;
	flex-wrap: wrap;
	width: 38%;
	height: 100%;
}

.Resorts-scrollable {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: calc(100vh - var(--logo-container-height) - var(--header-row-height));  /*	height of logo and top row*/
	overflow-y: auto;
}

.Resorts-scrollable-w-upd {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: calc(100vh - var(--logo-container-height) - var(--header-row-height) - 122px);  /*	height of logo and top row and upd banner*/
	overflow-y: auto;
}

.LogoContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	width: 100%;
	min-height: var(--logo-container-height);
}


.ResortsTopRow {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: var(--header-row-height);
	padding-left: var(--padding-unit);
	padding-right: var(--padding-unit);
/*	padding-bottom: 10px;*/
	color: #00325B;
	font-family: 'avenir-next-bold';
	font-weight: 900;
	font-size: 18px;
}

.Resort-name-column {
	flex-shrink: 1;
	width: 100%;
	align-items: center;
	gap: 10px;
	justify-content: flex-start;
	text-align: left;
	display: flex;
	font-family: 'avenir-next';
	font-size: 18px;
}


.Resort-new-column {
	flex-shrink: 0;
	width: 80px;
	text-align: center;
}

.Resort-base-column {
	flex-shrink: 0;
	width: 80px;
	text-align: right;
}

.Resort-closed-column {
	flex-shrink: 0;
	width: 280px;
	text-align: right;
}


.Resorts-list-item .closingDate {
	position: absolute;
	top: 7px;
	right: var(--padding-unit);
	font-size: 10px;
	color: #00263E;
}

.Resorts-list-item-active .closingDate {
	position: absolute;
	top: var(--padding-unit);
	right: var(--padding-unit);
	font-size: 10px;
}

@keyframes activate {
  from {background-color: #F1F3F7; min-height: var(--inactive-min-height);}
  to {background-color: #ffffff; min-height: var(--active-min-height);}
}

@keyframes deactivate {
  from {background-color: #ffffff; min-height: var(--active-min-height);}
  to {background-color: #F1F3F7; min-height: var(--inactive-min-height);}
}

.Resorts-list-item {
	scroll-margin: calc(var(--inactive-min-height)/2);
	width: 100%;
	display: flex;
	justify-content: space-between;
/*	border-bottom: 5px solid #F1F3F7;*/
	margin-bottom: 5px;
	background-color: #E5E7EA;
/*	animation: deactivate .1s;*/
	align-items: center;
	padding-left: var(--padding-unit);
	padding-right: var(--padding-unit);
	min-height: var(--inactive-min-height);
}

.Resorts-list-item-active {
	scroll-margin: calc(var(--active-min-height)/2);
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: #ffffff;
	border-bottom: 5px solid #F1F3F7;
	color: #00325B;
	align-items: center;
/*	animation: activate .1s;*/
	padding-left: var(--padding-unit);
	padding-right: var(--padding-unit);
	min-height: var(--active-min-height);
}


.Resorts-list-item-active .Resort-name-column {
	font-weight: bold;
}


@keyframes timergo {
	from {
		width: 0px;
	}
	to {
		width: calc(100% - 1px)
	}
}

.ProgressBar-dot {
	width: 7px;
	background-color: #E74F3D;
/*	background-image: linear-gradient(to right, #d9d9d9 , #E74F3D);*/
	height: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}
.ProgressBar {
	width: 7px;
	height: 7px;
	background-color: #d9d9d9;
	position: absolute;
	bottom: 0;
	left: 0;
	animation-name: timergo;
	animation-duration: var(--time-per-resort);
	animation-timing-function: linear;
}

.label-1 {
	text-transform: uppercase;
	font-size: 24px;
	font-family: 'avenir-next';
	font-weight: 200;
	color: #565756;
	margin-bottom: 10px;
}

.label-2 {
	text-transform: uppercase;
	font-size: 20px;
	font-family: 'avenir-next-bold';
	font-weight: 900;
	color: #00325B;
	margin-bottom: 10px;
}

.label-3 {
	font-family: 'avenir-next-bold';
	font-weight: 900;
	font-size: 18px;

	&.upd-red {
		font-size: 20px;
	}
}

.label-4 {
	color: #008EC8;
	font-size: 10px;
}

.label-5 {
	color: #00325B;
	font-size: 20px;
}

.label-6 {
	color: #565756;
	font-size: 20px;
	text-transform: uppercase;
}

.body-text {
	/* Chance T-storms then Partly Cloudy A 50 percent chance of showers and thunderstorms before midnight. Mostly cloudy, then gradually becoming mostly clear, with a low around 46. South southwest wind 10 to 15 mph becoming light southwest after midnight. */

	font-family: 'avenir-next';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
	text-transform: capitalize;

	/* Dark Grey */
	color: #565756;
}

.Resorts-list-item-title {
	color: #000;
	font-weight: bold;
}

.ResortDetails {
	display: flex;
	flex-wrap: wrap;
	width: 62%;
	height: 100%;
	background-color: #F1F3F7;
	color: #565756;
	max-height: 100%;
	overflow-y: hidden;
	align-items: stretch;
	column-gap: var(--main-gap);
/*	row-gap: var(calc(--main-gap / 2));*/
	opacity: 1;
	transition: opacity 1s ease;

	&.visible {
		opacity: 1;
	}

	&.hidden {
		opacity: 0;
	}
}

.text-center {
	text-align: center;
}
.Resort-details-title {
	font-size: 64px;
	font-family: 'avenir-next-bold';
	font-weight: 900;
	color: #00325B;
}

.Resort-details-last-updated {
	position: absolute;
	left: 0;
	bottom: -10px;
	background-color: #F1F3F7;
	padding-right: var(--padding-unit);
	font-style: italic;
}

.Resort-details-last-updated b {
	color: red;
}



.ResortDetails-section-1 {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #D9D9D9;
	height: var(--logo-container-height);
}


.ResortDetails-section-2 {
	width: 100%;
	display: flex;
}


.SnowAmount {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.SnowAmount-amount {
	font-size: 96px;
	font-family: 'avenir-next-bold';
	font-weight: 900;
	color: #00325B;
}

.ResortDetails-section-3 {
	width: 100%;
	display: flex;
	gap: var(--main-gap);
	align-items: flex-start;
}

.LastThreeSnowfalls {
	flex: 1;
}

.LastThreeSnowfalls-inner {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.LastThreeSnowfalls-inner-amount {
	font-size: 48px;
	font-family: 'avenir-next-bold';
	font-weight: 900;
	color: #00325B;
}

.Roads {
	flex: 1;
}

.Roads-top {
	display: flex;
	justify-content: flex-start;
	gap: 20px;
}

.Roads-road-tabs {
	display: flex;
	flex-wrap: nowrap;
	gap: 10px;
/*	margin-bottom: 30px;*/
}

.road-tab, .active-road-tab {
	width: 21px;
	height: 21px;
	border-radius: 21px;
	background-color: #D9D9D9;
}

.active-road-tab {
	background-color: #00325B;
}

/*.Roads marquee {
	background-color: #333333;
	padding: 1em 0;
	color: yellow;
}*/



.daysUntilOpenUtahPowderDay {
	color: white;
/*	background-color: blue;*/
}

.daysUntilOpen {
	
}

.Resorts-detail-section-4 {
	width: 100%;
	display: flex;
	gap: var(--main-gap);
}

.Forecast {
	flex: 1;
}

.Forecast-inner {
}

.Forecast-tab-buttons {
	display: flex;
	justify-content: space-between;
}

.Forecast-tab-content {
	margin-top: 20px;
}

.tab {
	font-family: 'avenir-next';
	font-size: 20px;
	font-weight: 900;

	text-transform: uppercase;

	color: #C1C1C1;

}

.active-tab {
	font-family: 'avenir-next';
	font-size: 20px;
	font-weight: 900;
	text-transform: uppercase;
	color: #00325B;
}

.Webcam {
	flex: 1;
}

.Webcam-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	background-image: url(../public/loading.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.Webcam-image {
	object-fit: contain;
	width: 100%;
	height: 350px;
}

.Webcam-video {
	object-fit: contain;
	width: 100%;
	height: 350px;
}


.LightLoading {
	background-image: url(../public/loading.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 200px;
}

.DarkLoading {
	background-image: url(../public/loading-dark.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 200px;
}
.hide-me {
	display: none;
}


.resort-list-is-powder-day {
	background-color: #00325B;
	color: #ffffff;
}

.GlobalUtahPowderDay {
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	background-color: #00325B;
	color: #ffffff;
	font-size: 22px;
}

.ResortRowOpen-upd-logo {
	width: 37px;
	height: 33px;
}

.GlobalUtahPowderDay-upd-logo {
	width: 93px;
	height: 82px;
}

.upd-red {
	color: #E74F3D;
}

.loading {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--light-gray);
}


.Transition {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 62%; /* only cover the resort details*/
	background: var(--light-gray);
	opacity: 0;
	transition: opacity 1s;
}

.Transition.is-active {
	opacity: 1;
}


/*.Resort-details-logo {
	height: 120px;
}*/